import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const reserveBtn = document.querySelector('#reserve');

// 분양소유자 팝업창
const formCheck = ($form) => {
    const $input = $form.find('input, select').toArray();
    let aaa = 1;

    if ($form) {
        const $div = document.getElementById("reserve-no");

        if($input[0].value == "접수") {
            $div.setAttribute('data-optional', 'skip')
        } else {
            $div.setAttribute('data-optional', 'check')
        }

        $($input).each((i,v) => {
            if(v.value === '' && $(v).data('optional') != "skip") {
                alert($(v).data('check'));
                $(v).focus();
                aaa = 99;
                return false;
            }
        });
    } 

    return aaa;
}

async function membership() {
    const $form = $('#memform');
    const ipts = document.querySelector('#memform');
    const aa = formCheck($form);
    
    if(aa < 99) {
        try {
            await axios
                .post('/send', ipts)
                .then(function (res) {
                    if(res.data.title == '접수') {
                        var msg = '예약 접수가 완료되었습니다.\n\n객실 예약은 관리비 혹은 업그레이드 비용이 결제되어야 진행되며,\n결제를 진행할 수 있는 링크는 담당자가 확인 후 기재해 주신 번호로 발송예정입니다.\n\n* 15시 이전 접수건 : 당일 발송\n* 15시 이후 접수건 : 익일 순차발송';
                    } else if(res.data.title == '변경') {
                        var msg = '예약 변경 접수가 완료되었습니다.\n오후 4시 이전 접수건은 당일 연락을 원칙으로 드립니다.\n오후 4시 이후 건은 익일 연락드릴 수 있음을 양해 부탁드립니다.';
                    } else {
                        var msg = '예약 취소가 완료되었습니다.\n예약시 추가결제하셨다면 환불, 결제 취소건은 카드사, 결제사 취소 요청 후 3~5영업일이 소요됩니다.';
                    }

                    if (res.status === 200) {
                        alert(msg);
                        location.href = "";
                    } else {
                        alert("접수가 실패했습니다.");
                    }
                })
                .catch(function (error) {
                    alert("접수가 실패했습니다.");                 
                });
        
        } catch (error) {
            alert("접수가 실패했습니다.");
        }
    }   
}

if (reserveBtn) {
    reserveBtn.addEventListener("click", membership);
}



$(function(){
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            $(".top-wrap").addClass("active");
        } else {
            $(".top-wrap").removeClass("active");
        }
    });
    
    // 탑메뉴 스크롤
    $('.top-menu li > a').click(function (){
        console.log($('.top-wrap').outerHeight)
        $('html, body').animate({scrollTop: $(this.hash).offset().top - $('.top-wrap').outerHeight() + 1}, 600);
    });

    // 분양소유자팝업
    // 입실 불가
    const today = new Date();

    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    const dateString = year + '-' + month  + '-' + day;

    const hours = today.getHours(); // 시
   
    const dayArr = [
        ['2024-09-13', '2024-09-17'], 
        ['2024-10-03', '2024-10-05'], 
        ['2024-12-24', '2024-12-25'], 
        ['2024-12-31'],[],
    ]

    if(hours > 12) {
        const idx = dayArr.length - 1;
        dayArr[idx].push(dateString);
    }

    const getDates = (dayArr) => {
        return dayArr.reduce((acc, cur) =>  {
            let currentDate = dayjs(cur[0])
            acc.push(cur[0])
            while (cur.length > 1 && currentDate.isBefore(cur[1])) {
                currentDate = currentDate.add(1, 'day')
                acc.push(currentDate.format("YYYY-MM-DD"))
            }
            return acc
        },[])
    }

    const disableDay = getDates(dayArr)
    const toDay = dayjs()
    const dfDay = disableDay.reduce((acc, cur) => acc = cur === acc.format("YYYY-MM-DD") ? dayjs(acc).add(1, 'day') : acc, toDay).format("YYYY-MM-DD")

    flatpickr(".mchkin", {
        minDate: new Date(),
        // 문자열이 오늘 날짜면 오류
        //defaultDate: dfDay === dayjs().format('YYYY-MM-DD') ? new Date() :  dfDay,
        disableMobile: "true",
        locale: "ko",
        disable: disableDay
    });
    
    flatpickr(".mchkout", {
        minDate: new Date().fp_incr(1),
        //defaultDate: new Date().fp_incr(1),
        disableMobile: "true",
        locale: "ko",
    });
    
    $(".mchkin").change((e) => {
        let ckinDate = $(e.currentTarget).val()
        let $ckout = $(e.currentTarget).next()
        let ckoutDate = moment(ckinDate).add(1, "days").format('YYYY-MM-DD')
        flatpickr($ckout, {
            minDate: ckoutDate,
            defaultDate: ckoutDate,
            disableMobile: "true",
            locale: "ko",
        })
    })

    $(".mchkout").change((e) => {
        let ckinDate = $('.mchkin').val()
        let ckoutDate = $('.mchkout').val()
        for(let day of disableDay){
            if(dayjs(day).isBetween(ckinDate, ckoutDate)){
                alert('이용기간 중 이용 불가일이 포함되어 있습니다.')
                flatpickr('.mchkout', {
                    minDate: dayjs(ckinDate).add(1, "d").format('YYYY-MM-DD'),
                    defaultDate: dayjs(ckinDate).add(1, "d").format('YYYY-MM-DD'),
                    disableMobile: "true",
                    locale: "ko",
                })
                break;
            }
        };
    })


    $('.popup-wrapper .it-wrap .it').click((e) => {
        $('.popup-wrapper .it-wrap .it').removeClass('active')
        $(e.currentTarget).addClass('active')
        $('.popup-wrapper input[name=type]').val($(e.currentTarget).data('type'))

        if($(e.currentTarget).data('type') == "접수") {
            document.getElementById("reserve-no").style.display = "none";
        } else {
            document.getElementById("reserve-no").style.display = "inline";
        }
    })

    $(".popup-wrapper input[type=checkbox]").click((e) => {
        if($(e.currentTarget).hasClass('except')) return false; //중복 js 제외
        $(e.currentTarget).is(':checked') ? $(e.currentTarget).val('y') : $(e.currentTarget).val('')
    })

    $('.tel').on("keyup",function() {
        const prev    = $(this).val();
        const str     = prev.replace(/[^0-9]/g, "").replace(/(^02|^0505|^01.{1}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,"$1-$2-$3").replace("--", "-");
        $(this).val(str);
    });
    
    $(".info-popup .tab-wrap >li").click((e) => {
        $('.info-popup .tabCont >li').hide()
        $('.info-popup .tabCont >li').eq($(e.currentTarget).index()).show()
    })
    
    $(".info-popup .fold-wrap h5").click((e) => {
        $(e.currentTarget).siblings('.fold').toggle()
    })

    const PopupModule = {
        // 팝업 초기화
        initPopup(popupId, nextId) {
            const today = moment().format('YYYY-MM-DD');
            const closedDate = localStorage.getItem(`closedDate_${popupId}`);

            console.log(popupId,  nextId)
            closedDate !== today 
            ? $(`.${popupId}`).show() 
            : nextId && $(`.${nextId}`).show();
            
            $('.todayClosed').click(() => {
                localStorage.setItem(`closedDate_${popupId}`, today);
                $(`.${popupId}`).hide();
            });

        },
    
        // 모든 팝업 초기화
        init() {
            const closeBtn = $('.todayClosed')

            if (closeBtn.length === 0) return;

            closeBtn.each((_, popup) => {
                const popupId = $(popup).data('popup');
                const nextId = $(popup).data('next')
                this.initPopup(popupId, nextId );
            });
        }
      };
      
    PopupModule.init()
  
});